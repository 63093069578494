import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { PushMessageService, PushNotificationType } from './modules/domain-services/push-message.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'biptt-manager-frontend';

  token: BehaviorSubject<string> = new BehaviorSubject(null);
  hasNotification: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // lastMessage$: BehaviorSubject<PushNotificationType> = new BehaviorSubject(null);

  notificationTitle: string;
  notificationBody: string;
  canNotify: boolean = false;

  messageChannel = new MessageChannel();

  constructor(
    private activatedRoute: ActivatedRoute,
    // private pushMessageService: PushMessageService,
    private translate: TranslateService,
  ) {
    this.translate.addLangs(['en', 'pt-br']);
    const userLang = navigator.language;
    if (userLang.toLowerCase() == 'pt' || userLang.toLowerCase() == 'pt-br' || userLang.toLowerCase() == 'pt-pt') {
      this.translate.setDefaultLang('pt-br');
    } else {
      this.translate.setDefaultLang('en');
    }

    this.activatedRoute.queryParams.subscribe(params => {
      let attributes_count = Object.keys(params).length;
      if (attributes_count > 0) {
        var sanitizedUrlParams = {};
        for (var i in params) {
          sanitizedUrlParams[i] = this.stringToBoolean(params[i]);
          if (/^\d+$/.test(params[i])) {
            sanitizedUrlParams[i] = Number(params[i]);
          }
        }
        localStorage.setItem('biptt_url_params', JSON.stringify(sanitizedUrlParams));
      }
    });
  }


  ngOnInit(): void {
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('firebase-messaging-sw.js')
    //     .then(function (reg) { // registration worked console.log('Registration succeeded. Scope is ' + reg.scope);
    //     }).catch(function (error) {
    //       // registration failed
    //       console.error('Registration failed with ' + error);
    //     });

    //     navigator.serviceWorker.addEventListener('message', function handler(event) {
    //       console.debug('Received a message from service worker: ', event.data);
    //     });

    // } else console.error('Cannot use push notification API> No Browser support available.');

    // this.hasNotification.subscribe(has => this.canNotify = has);
    // this.pushMessageService.tokenSubject
    //   .subscribe(token => this.token.next(token));

    // this.pushMessageService.getLastMessage()
    //   .subscribe(({ notification }) => {
    //     this.lastMessage$.next({ notification });
    //     this.notificationBody = notification?.body;
    //     this.notificationTitle = notification?.title;
    //     this.hasNotification.next(true);
    //   });

    // this.pushMessageService.receiveMessage();
    // this.pushMessageService.receiveBackgroundMessage();
  }

  // hideModal() {
  //   this.canNotify = false;
  // }

  // updatePage() {
  //   window.location.reload();
  // }

  stringToBoolean(string: any) {
    if(string.toLowerCase() == "true") {
      return true;
    } else if (string.toLowerCase() == "false") {
      return false;
    } else {
      return string;
    }
  }
}
