import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { LayoutModule } from '@angular/cdk/layout';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxStripeModule } from 'ngx-stripe';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireAuthModule } from '@angular/fire/auth'
// import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { environment } from 'src/environments/environment';
// import { PushMessageService } from './modules/domain-services/push-message.service';
import { AsyncPipe } from '@angular/common';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OverlayModule,
    LayoutModule,
    MatDialogModule,
    NgxStripeModule.forRoot('pk_test_51HtGb4Hu6GbHcnMw6zZKXWQIc5HQ78WD6us5fYDPqreOqnerNVTD1Pr6e3HqE28jEEx7e609PcXuKunEUxFceb8d00xuI3HI42'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([
      // {
      //   path: 'login',
      //   loadChildren: './login/login.module#LoginModule',
      // },
      {
        path: '',
        loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
      }
    ], {
        paramsInheritanceStrategy: 'always',
      }),
    // AngularFireMessagingModule,
    // AngularFireAuthModule,
    // AngularFireAuthGuardModule,
    // AngularFireModule.initializeApp(environment.firebase),
  ],
  exports: [
    TranslateModule
  ],
  bootstrap: [AppComponent],
  providers: [/** PushMessageService,*/ AsyncPipe]
})
export class AppModule { }
